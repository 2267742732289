import { useContext, useState } from "react";

import { BottomBar, Menu, Seo } from "~components";
import { BaseContext } from "~context";

import { useRouter } from "next/router";
import { Layout } from "~components/common/Layout";
import { List, SubHeader } from "~components/events";
import {
  EventsServiceReturn,
  useEventsService,
} from "~features/events/hooks/useEventsService";
import { usePointsService } from "~features/points/hooks/usePointsService";
import { useOrganization } from "~hooks";

type QueryParams = {
  tourId: string;
};

const EventsPage = () => {
  const router = useRouter();
  const { tourId } = router?.query as QueryParams;

  const events = useEventsService({
    search: undefined,
    tourId,
    includeFeaturedEvents: true,
  });

  return <Events {...events}></Events>;
};

const Events = ({
  events,
  featuredEvents,
  isLoading,
  error,
}: EventsServiceReturn) => {
  const { organization, hasFeature } = useOrganization();
  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState<string>(undefined);

  const filteredEvents = search?.trim()
    ? events.filter((e) =>
        e.title.toLocaleLowerCase().includes(search.trim().toLocaleLowerCase())
      )
    : events;

  const toggleSearch = () => setShowSearch(!showSearch);

  const {
    point,
    pointItems,
    error: pointsError,
    isLoading: pointsLoading,
  } = usePointsService({
    includeItems: true,
    enabled: hasFeature("pointsOnHomepage"),
  });

  return (
    <>
      <Seo
        title={`Events - ${organization?.name} `}
        description={undefined} // TODO: need to generate a rich description - just use page content for now
      />
      <Menu>
        <SubHeader
          showSearch={showSearch}
          setSearch={setSearch}
          toggleSearch={toggleSearch}
          hasBackground={!!organization?.branding?.background}
        />
      </Menu>
      <Layout layoutType="main">
        <List
          isLoading={isLoading}
          error={error}
          setSearch={setSearch}
          events={filteredEvents}
          featuredEvents={featuredEvents}
          pointItems={pointItems}
          pointName={point?.name}
        />
      </Layout>
      <BottomBar />
    </>
  );
};

export default EventsPage;
